import React from 'react'
import styles from './HowItWorks.module.css';

const HowItWorks = () => {
    return (
        <article
            className={styles.article}
            style={{ backgroundImage: `url(https://farmeasy.co.in/API/Images/farmeasy.jpg)` }}
        >
            <div style={{ backgroundColor: 'rgba(41, 41, 41, 0.7)' }}>
                {/* <div class="title" style={{ marginTop: 50 }}>
                    <span>
                        <span class="summer">FarmEasy</span>
                    </span>
                </div> */}
                <div class="title" style={{ marginTop: 50 }}>
                    <span style={{ textShadow: 'initial', color: 'white' }}>
                        How to Order
                    </span>
                </div>
                <div class="description">
                    <h4 style={{ color: 'whitesmoke' }}>
                        How to order with us?
                    </h4>
                </div>
            </div>
        </article>
    );
}

export default HowItWorks