import React from 'react'
import styles from './OurOfferings.module.css';

const OurOfferings = () => {
    return (
        <article
            className={styles.article}
            style={{ backgroundImage: `url(https://farmeasy.co.in/API/Images/farmeasy.jpg)` }}
        >
            <div style={{ backgroundColor: 'rgba(41, 41, 41, 0.7)' }}>
                {/* <div class="title" style={{ marginTop: 50 }}>
                    <span>
                        <span class="summer">FarmEasy</span>
                    </span>
                </div> */}
                <div class="title" style={{ marginTop: 50 }}>
                    <span style={{ textShadow: 'initial', color: 'white' }}>
                        Our Offerings
                    </span>
                </div>
                <div class="description">
                    <h4 style={{ color: 'whitesmoke' }}>
                        What we offer to our customers.
                    </h4>
                </div>
            </div>
        </article>
    );
}

export default OurOfferings