import logo from './logo.svg';
import './App.css';
import AppHeader from './Components/AppHeader';
import { Image, Space, Modal, Input, Drawer, DrawerProps, RadioChangeEvent } from "antd";
import SideMenu from './Components/SideMenu';
import PageContent from './Components/PageContent';
import AppFooter from './Components/AppFooter';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react';
import Background from './Website/Background/BackgroundModel';
import WhyChooseUs from './Website/WhyChooseUs/WhyChooseUs';
import OurOfferings from './Website/OurOfferings/OurOfferings';
import HowItWorks from './Website/HowItWorks/HowItWorks';
import AwesomeTestimonial from 'react-awesome-testimonials';
import DropDown from './Website/DropDown/DropDown';
import Vision from './Website/Vision/Vision';

function App() {

  const [showADmin, setShowAdmin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pageSelected, setPageSelected] = useState("Home");
  const [showSellerForm, setShowSellerForm] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [sellerLocation, setSellerLocation] = useState("");
  const [sellerPhoneNumber, setSellerPhoneNumber] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");

  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    console.log(mobile);
    console.log(window.innerWidth)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      console.log(mobile);
      console.log(window.innerWidth)
    }

  }, []);

  const checkLogin = () => {
    if (username.trim() === "") {
      alert("Please enter proper username")
    } else if (password.trim() === "") {
      alert("Please enter proper password")
    } else if (username.trim() === "farmeasy1230@gmail.com" && password.trim() === "farm@1230#") {
      setIsModalVisible(false);
      setShowAdmin(true)
    } else {
      alert("Wrong username and password")
    }
  }

  const checkSeller = () => {
    if (sellerName.trim() === "") {
      alert("Please enter proper seller name")
    } else if (sellerLocation.trim() === "") {
      alert("Please enter proper location")
    } else if (sellerPhoneNumber.trim() === "" || sellerPhoneNumber.length < 10) {
      alert("Please enter proper mobile number")
    } else {
      setSellerName("");
      setSellerLocation("")
      setSellerPhoneNumber("")
      setSellerEmail("")
      setShowSellerForm(false)
      alert("Seller Registered SuccessFully. We will contact you soon.")
    }
  }

  if (showADmin === false) {
    return (
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Starbucks Landing Page</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

          {/* <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap"
            rel="stylesheet"
          /> */}
          <link rel="stylesheet" href="/style.css" />
          <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        </head>
        <body>
          <div class="container">
            <div class="navbar">
              
              <div class="navbar__logo">
                <Image
                  onClick={() => setPageSelected("Home")}
                  preview={false}
                  width={180}
                  src="https://farmeasy.co.in/API/Images/logo.png" />
              </div>
              {mobile ?
                <DropDown setPageSelected={setPageSelected} /> :
                <div />}
              <div class="navbar-menu__wrapper" style={{ marginBottom: 20, marginLeft: 50, marginTop: 10 }}>
                <div class="navbar__menu">
                  <a href="#" style={{ width: 70, textSizeAdjust: 'auto' }} class="active" onClick={() => setPageSelected("Home")}>Home</a>
                  <a href="#" style={{ width: 70, textSizeAdjust: 'auto' }} class="active" onClick={() => setPageSelected("Vision")}>Vision</a>
                  <a href="#" style={{ width: 70, textSizeAdjust: 'auto' }} class="active" onClick={() => setPageSelected("WhyChooseUs")}>Why Us</a>
                  <a href="#" style={{ width: 70, textSizeAdjust: 'auto' }} class="active" onClick={() => setPageSelected("OurOffering")}>Our Offering</a>
                  <a href="#" style={{ width: 100, textSizeAdjust: 'auto' }} class="active" onClick={() => setPageSelected("HowItWorks")}>How to Order</a>
                  {/* <a href="https://farmeasy.co.in/refundpolicy.html"> Refund Policy </a>
                  <a href="https://farmeasy.co.in/privacypolicy.html"> Privacy Policy </a>
                  <a href="https://farmeasy.co.in/cancellationpolicy.html"> Cancellation Policy </a>
                  <a href="https://farmeasy.co.in/termsandconditions.html"> Terms and Conditions </a>
                  <a href="https://farmeasy.co.in/shippingpolicy.html"> Cancellation Policy </a> */}
                </div>
                <div class="navbar__auth">
                  <button onClick={() => setIsModalVisible(true)} class="sign-in">Admin Login</button>
                </div>
              </div>
            </div>
            {pageSelected === "Home" ?
              <div>
                <div class="hero__content">
                  <Background />
                  <div class="title" style={{ marginTop: 30 }}>
                    <span>
                      About Us
                    </span>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      FamEasy Natural & Fresh company is working since 2023. The only director of the company is Nishant Avhad. He established this company with the help of his father and is running it well.
                    </span>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      FarmEasy is B2B business Model. FarmEasy is a digital platform joined by many farmers as well as many commercial traders , hotel owners and food processing companies who require large quantities of fruits and vegetables. FarmEasy is providing 2 or 3 quality of per vegetables and fruits for customers. so customer can choose suitable for him the quality and buy it easily . These purchased goods are delivered at home within a very short time. FarmEasy became the link between the farmer and this company.
                    </span>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      FarmEasy accepts both cash and online payment cards But does not work on credit.
                    </span>
                  </div>

                </div>
                <div class="w3-row-padding ">
                  <div class="w3-row-padding w3-center">
                    <div class="w3-third">
                      <div class="w3-container">
                        <Image
                          preview={false}
                          width={250}
                          src="https://farmeasy.co.in/API/Images/delivery-truck.png" />
                        <h2>Fast Delivery</h2><br />
                        <p>Our Drivers deliver to your doorsteps without damages and delays.</p>
                      </div>
                    </div>

                    <div class="w3-third">
                      <div class="w3-container">
                        <h2></h2><br />
                        <h2></h2><br />
                        <Image
                          preview={false}
                          width={250}
                          marginVertical={50}
                          src="https://farmeasy.co.in/API/Images/veggies.png" />
                        <h2></h2><br />
                        <p></p>
                        <h2>Fresh Vegetables</h2><br />
                        <p>Our Veggies comes directly from the farmers everyday. So no chance they are rotten.</p>
                        <p></p>
                      </div>
                    </div>

                    <div class="w3-third">
                      <div class="w3-container">
                        <h2></h2><br />
                        <h2></h2><br />
                        <Image
                          preview={false}
                          width={150}
                          src="https://farmeasy.co.in/API/Images/get-money.png" />
                        <h2></h2><br />
                        <h2>Wholesale Prices</h2><br />
                        <p>We offer less prices for products than market on wholesale purchase.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="title" style={{ marginTop: 50 }}>
                  <span>
                    See What People have to say about us
                  </span>
                </div>
                <div class="w3-row-padding w3-center">
                  <div class="w3-third">
                    <div class="w3-container">
                      <Image
                        preview={false}
                        width={250}
                        height={250}
                        style={{ marginBottom: 20 }}
                        src="https://png.pngtree.com/png-vector/20240205/ourmid/pngtree-young-indian-man-png-image_11622293.png" />
                      <h2>Sushant Mali</h2><br />
                      <p>I always got deliveries on time and at best price. For my catering business I order from them regularly.</p>
                    </div>
                  </div>

                  <div class="w3-third">
                    <div class="w3-container">
                      <Image
                        preview={false}
                        width={250}
                        height={250}
                        style={{ marginBottom: 20 }}
                        src="https://i.pinimg.com/originals/ea/cb/1a/eacb1af18e6a8651d0c23ea7b0a0e706.png" />
                      <h2>Nitin Patil</h2><br />
                      <p>For my restaurant I always prefer to order from FarmEasy. Quick Delivery is their speciality and their vegetables are always fresh.</p>
                    </div>
                  </div>

                  <div class="w3-third">
                    <div class="w3-container">
                      <Image
                        preview={false}
                        width={250}
                        height={250}
                        style={{ marginBottom: 20 }}
                        src="https://png.pngtree.com/png-vector/20230928/ourmid/pngtree-young-indian-man-png-image_10149662.png" />
                      <h2>Nishant</h2><br />
                      <p>FarmEasy is best to get Vegetables at Nice Prices.</p>
                    </div>
                  </div>
                </div>
                <div class="title" style={{ marginTop: 50 }}>
                  <span>
                    Connect with Us
                  </span>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    Ready to elevate your menu with farm-fresh ingredients? Download the FarmEasy app today and start ordering. For inquiries or assistance, feel free to contact us – we're here to support your culinary business every step of the way.
                  </span>
                </div>
                <button style={{ marginBottom: 50 }} onClick={() => setShowSellerForm(true)} class="order-now">Register as Seller</button>
              </div> :
              pageSelected === "Vision" ?
              <div>
                <Vision />
                <div class="title" style={{ marginTop: 30 }}>
                  <h1>
                    Vision
                  </h1>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    Our vision is to revolutionize the farming industry by making organic practice the norms . we strive to create a world where farmers thrive , soil quality is preserved , and communities are nourished by healthy , chemical – free produce
                  </span>
                </div>
                <div class="title" style={{ marginTop: 30 }}>
                  <h1>
                    Mission
                  </h1>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    At Farmeasy our mission is to assists farmers in overcoming the challenges they face in mandi markets. We aim to directly connect them with small retailers and food processing companies, ensuring fair prices and sustainable practices.
                  </span>
                </div>
                <div class="title" style={{ marginTop: 30 }}>
                  <h1>
                    Achivement
                  </h1>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    2021 – 2022 – We planned for our work and make path clear
                  </span>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    2023 – Successfully registered our company .
                  </span>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    2024 – Launch our Android App on play Store and began operations. Completed  our first order worth Rs.7000. on the launch day. By December, achieved daily order volumes of Rs.50000-Rs.60000.
                  </span>
                </div>
                <div class="descriptionaboutus">
                  <span>
                    2025 – Loading... {"(More achievements to come!)"}
                  </span>
                </div>
              </div> :
              pageSelected === "WhyChooseUs" ?
                <div>
                  <WhyChooseUs />
                  <div class="title" style={{ marginTop: 30 }}>
                    <h1>
                      Freshness Guaranteed
                    </h1>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      We source our vegetables from local farms, ensuring the freshest, most nutritious produce for your business. Our products are harvested and delivered promptly to maintain peak quality.
                    </span>
                  </div>
                  <div class="title" style={{ marginTop: 30 }}>
                    <h1>
                      Convenient Mobile App
                    </h1>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      Manage your orders, track deliveries, and customize your vegetable supply through our intuitive mobile app. Whether you're placing a one-time order or setting up a recurring delivery, our app makes it simple and convenient to meet your needs.
                    </span>
                  </div>
                  <div class="title" style={{ marginTop: 30 }}>
                    <h1>
                      Reliable Delivery Service
                    </h1>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      With our efficient delivery network, you can trust us to deliver your order on time – every time. We offer flexible scheduling options to ensure that your kitchen is always stocked and ready to serve.
                    </span>
                  </div>
                  <div class="title" style={{ marginTop: 30 }}>
                    <h1>
                      Sustainably Sourced
                    </h1>
                  </div>
                  <div class="descriptionaboutus">
                    <span>
                      We believe in supporting local farmers and promoting sustainability. Our vegetables are grown with care, and we prioritize eco-friendly practices throughout our supply chain.
                    </span>
                  </div>
                </div> :
                pageSelected === "OurOffering" ?
                  <div>
                    <OurOfferings />
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Wide Variety of Fresh Produce
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        From leafy greens to seasonal specialties, we provide an extensive range of vegetables that cater to diverse culinary needs. Our offerings are curated to suit all types of cuisines, ensuring that you always have the perfect ingredients for your dishes.
                      </span>
                    </div>
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Custom Orders & Special Requests
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        We understand that each kitchen is unique. That's why we offer the flexibility to place custom orders, whether you're looking for hard-to-find vegetables or bulk supplies for special events.
                      </span>
                    </div>
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Competitive Pricing
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        We offer transparent pricing with no hidden fees. With our bulk purchasing options, you can enjoy great value without compromising on quality.
                      </span>
                    </div>
                  </div> :
                  <div>
                    <HowItWorks />
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Download the App & Sign Up
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        Download the FarmEasy app on iOS or Android. Create an account.
                      </span>
                    </div>
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Browse & Order
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        Browse our wide selection of fresh vegetables and place your order with just a few taps.
                      </span>
                    </div>
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Flexible Delivery
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        Choose a delivery time that works best for you. We offer both scheduled and on-demand delivery options.
                      </span>
                    </div>
                    <div class="title" style={{ marginTop: 30 }}>
                      <h1>
                        Enjoy Freshness
                      </h1>
                    </div>
                    <div class="descriptionaboutus">
                      <span>
                        Receive your vegetables fresh and ready to use, directly from the farm to your kitchen.
                      </span>
                    </div>
                  </div>}
            <Space style={{ marginTop: 50 }}>
              <footer class="footer-distributed">
                <div class="footer-left">

                  <h3>Farm<span>Easy</span></h3>

                  <p class="footer-links">
                    <a href="#" class="link-1">Home</a>

                    <a href="https://farmeasy.co.in/refundpolicy.html">Refund Policy</a>

                    <a href="https://farmeasy.co.in/privacypolicy.html">Privacy Policy</a>

                    <a href="https://farmeasy.co.in/cancellationpolicy.html">Cancellation Policy</a>

                    <a href="https://farmeasy.co.in/termsandconditions.html">Terms and Conditions</a>

                    <a href="https://farmeasy.co.in/shippingpolicy.html">Shipping Policy</a>
                  </p>

                  <p class="footer-company-about">
                    <span>Company Profile</span>
                    <p>FarmEasy is a wholesale platform for fruits and vegetables. We serve fresh and natural fruits and vegetables. We allow hotels restaurants, retailers and fruit or vegetable processing companies to buy everything from vegetables and fruits. We offer both door step delivery and pick up point options.. Doorstep delivery charges are based on order quantity</p>
                  </p>

                  <div>
                    <i class="fa fa-map-marker"></i>
                    <p><span>FarmEasy Natural & Fresh OPC Pvt Ltd</span></p>
                    <p>PL No 17B, 17C & 20, V-1113, Sector - 19, Vashi, Navi Mumbai, Thane - 400705</p>
                  </div>

                  <div>
                    <i class="fa fa-envelope"></i>
                    <p><a href="mailto:farmeasynaturalandfresh01@gmail.com">farmeasynaturalandfresh01@gmail.com</a></p>
                  </div>

                  <div>
                    <i class="fa fa-phone"></i>
                    <p><a href="tel:+918652022005">8652022005</a></p>
                  </div>

                  <p class="footer-company-name">FarmEasy Natural & Fresh © 2015</p>
                </div>

                {/* <div class="footer-right">

                <p class="footer-company-about">
                  <span>Company Profile</span>
                  Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus vehicula sit amet.
                </p>

                <div class="footer-icons">

                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>

                </div>

              </div> */}

              </footer>
            </Space>

          </div>
          <Modal
            visible={isModalVisible}
            okText="Login"
            closable={false}
            cancelText="Close"
            onOk={() => {
              checkLogin()
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}>
            <div>
              <div className="mb-3">
                <label className="form-label" htmlFor="stock">
                  Enter Username
                </label>
                <Input value={username} className="form-control mb-1" id="stock" required onChange={(evt) => { setUsername(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Password
                </label>
                <Input pattern='password' value={password} className="form-control mb-1" id="stock" required onChange={(evt) => { setPassword(evt.target.value) }} />
              </div>
            </div>
          </Modal>
          <Modal
            visible={showSellerForm}
            okText="Register"
            closable={false}
            cancelText="Close"
            onOk={() => {
              //checkLogin()
              checkSeller()
            }}
            onCancel={() => {
              setSellerName("");
              setSellerLocation("")
              setSellerPhoneNumber("")
              setSellerEmail("")
              setShowSellerForm(false);
            }}>
            <div>
              <div className="mb-3">
                <label className="form-label" htmlFor="stock">
                  Enter Seller Name
                </label>
                <Input value={sellerName} className="form-control mb-1" id="stock" required onChange={(evt) => { setSellerName(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Seller Mobile
                </label>
                <Input value={sellerPhoneNumber} className="form-control mb-1" id="stock" required onChange={(evt) => { setSellerPhoneNumber(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Seller Location
                </label>
                <Input value={sellerLocation} className="form-control mb-1" id="stock" required onChange={(evt) => { setSellerLocation(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Seller Email {"(Optional)"}
                </label>
                <Input value={sellerEmail} className="form-control mb-1" id="stock" required onChange={(evt) => { setSellerEmail(evt.target.value) }} />
              </div>
            </div>
          </Modal>
        </body>

      </html>
    )
  } else {
    return (
      <div className="App">
        <AppHeader />
        <Space className="SideMenuAndPageContent">
          <SideMenu></SideMenu>
          <PageContent></PageContent>
        </Space>
      </div>
    );
  }
}

export default App;
