import React, { useState } from 'react'
import { Image } from 'antd';
import styles from './DropDown.module.css'
import { Space } from 'antd'

const DropDown = ({ setPageSelected }) => {

    const [showContent, setShowContent] = useState(false)

    return (
        <div class={styles.dropdown}>
            <Space direction='vertical'>
                <button class={styles.dropbtn} onClick={() => setShowContent(!showContent)}>
                    <Image
                        preview={false}
                        height={20}
                        width={20}
                        src="https://farmeasy.co.in/API/Images/more.png">

                    </Image>
                </button>
                {showContent ?
                    <Space direction='vertical' className={styles.dropdowncontent} style={{ marginBottom: 20 }}>
                        <a href="#"  style={{ 
                                padding: 10,
                                backgroundColor: 'lightgray',
                                textDecoration: 'none'
                            }} onClick={() => {
                            setPageSelected("Home")
                            setShowContent(false)
                        }}>Home</a>
                        <a href="#" style={{ 
                                padding: 10,
                                backgroundColor: 'lightgray',
                                textDecoration: 'none',
                            }} onClick={() => {
                            setPageSelected("Vision")
                            setShowContent(false)
                        }}>Vision</a>
                        <a href="#" style={{ 
                                padding: 10,
                                backgroundColor: 'lightgray',
                                textDecoration: 'none',
                            }} onClick={() => {
                            setPageSelected("WhyChooseUs")
                            setShowContent(false)
                        }}>Why Us</a>
                        <a href="#" style={{ 
                                padding: 10,
                                backgroundColor: 'lightgray',
                                textDecoration: 'none',
                            }} onClick={() => {
                            setPageSelected("OurOffering")
                            setShowContent(false)
                        }}>Our Offering</a>
                        <a href="#" style={{ 
                                padding: 10,
                                backgroundColor: 'lightgray',
                                textDecoration: 'none',
                            }} onClick={() => {
                            setPageSelected("HowItWorks")
                            setShowContent(false)
                        }}>How to Order</a>
                    </Space> : <div />}
            </Space>
        </div>
    )
}

export default DropDown